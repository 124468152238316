<template>
  <div>
    <PageHeader fileName="header-4" withTablet withMobile>
      <h3>{{ $lang("page_header") }}</h3>
    </PageHeader>

    <section class="py-6">
      <div class="container">
        <div class="row">
          <div class="col-lg-6">
            <h2 class="mb-4" v-html="$lang('section1.heading')" />
          </div>
          <div class="col-lg-6">
            <h6 class="fw-400 mb-4 mt-3">
              {{ $lang("section1.title") }}
            </h6>
            <p>
              {{ $lang("section1.body") }}
            </p>
          </div>
        </div>
      </div>
    </section>

    <section class="py-6 bg-dark">
      <div class="container">
        <div class="row">
          <div class="col-lg-6">
            <h3 v-html="$lang('meet_our_teachers')" />
            <h4 v-html="$lang('barcelona.title')" />
          </div>
          <div class="col-lg-6">
            <div class="form-row gap-y-2">
              <div
                class="col-md-6"
                v-for="(teacher, ti) of $lang('barcelona.team')"
                :key="ti"
              >
                <ImageContainer
                  :fileName="teacher.image"
                  class="img-container-rounded team-card"
                  :size="250"
                >
                  <div class="team-overlay">
                    <p class="name">
                      {{ teacher.first_name }} {{ teacher.last_name }}
                    </p>

                    <ul class="education">
                      <li v-for="(e, ei) of teacher.education" :key="ei">
                        {{ e }}
                      </li>
                    </ul>
                  </div>
                  <div class="team-grad-overlay">
                    <p class="name">
                      {{ teacher.first_name }}
                    </p>
                  </div>
                </ImageContainer>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-5">
          <div class="col-lg-6">
            <h4 v-html="$lang('madrid.title')" />
          </div>
          <div class="col-lg-6">
            <div class="form-row gap-y-2">
              <div
                class="col-md-6"
                v-for="(teacher, ti) of $lang('madrid.team')"
                :key="ti"
              >
                <ImageContainer
                  :fileName="teacher.image"
                  class="img-container-rounded team-card"
                  :size="250"
                >
                  <div class="team-overlay">
                    <p class="name">
                      {{ teacher.first_name }} {{ teacher.last_name }}
                    </p>

                    <ul class="education">
                      <li v-for="(e, ei) of teacher.education" :key="ei">
                        {{ e }}
                      </li>
                    </ul>
                  </div>
                  <div class="team-grad-overlay">
                    <p class="name">
                      {{ teacher.first_name }}
                    </p>
                  </div>
                </ImageContainer>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="py-6">
      <div class="container">
        <div class="row">
          <div class="col-lg-3 mb-4 mb-lg-0">
            <h4 class="mb-5 mb-lg-0 text-dark">
              {{ $lang("section_help.header") }} <br />
              <span class="material-symbols-outlined arrow_right_alt">
                arrow_right_alt
              </span>
            </h4>
          </div>
          <div class="col-lg-3 mb-4 mb-lg-0">
            <CardButton
              :title="$lang('section_help.card1.title')"
              :to="{ name: 'our_campus', hash: '#barcelona' }"
              theme="primary"
            >
              <small class="m-0">
                {{ $lang("section_help.card1.body") }}
              </small>
            </CardButton>
          </div>
          <div class="col-lg-3 mb-4 mb-lg-0">
            <CardButton
              :title="$lang('section_help.card2.title')"
              :to="{ name: 'our_campus', hash: '#madrid' }"
              theme="primary"
            >
              <small class="m-0">
                {{ $lang("section_help.card2.body") }}
              </small>
            </CardButton>
          </div>
          <div class="col-lg-3 mb-4 mb-lg-0">
            <CardButton
              :title="$lang('section_help.card3.title')"
              :to="{ name: 'our_courses' }"
              theme="primary"
            >
              <small class="m-0">
                {{ $lang("section_help.card3.body") }}
              </small>
            </CardButton>
          </div>
        </div>
      </div>
    </section>

    <ContactFormSection fileName="footer-4" theme="primary">
      {{ $lang("contact_form.location") }}
    </ContactFormSection>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader.vue";
import ContactFormSection from "@/components/ContactFormSection.vue";
import ImageContainer from "@/components/ImageContainer.vue";
import CardButton from "@/components/CardButton.vue";

export default {
  components: {
    PageHeader,
    ContactFormSection,
    CardButton,
    ImageContainer,
  },
  data: () => ({}),
};
</script>

<style></style>
